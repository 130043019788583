
import { memberSV } from "./add-member.service.js";
export default {
    props: ["infoApp"],
    data: function() {
        return {
            emailMember: ""
        }
    },
    methods: {
        addmember: function() {
            var thisVue = this;
            if (this.emailMember && this.emailMember.length > 5) {
                memberSV.addMemberSV(JSON.stringify({
                    "_type": "ADD_MEMBER",
                    "page_id": thisVue.infoApp.id,
                    "email": thisVue.emailMember
                }), function(e, res) {
                    if (res && res.code == 200) {
                        thisVue.$swal({
                            position: "bottom-start",
                            type: "success",
                            title: "successfully add member.",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    } else {
                        thisVue.$swal({
                            position: "bottom-start",
                            type: "error",
                            title: "Error",
                            showConfirmButton: false,
                            timer: 1500
                        });
                    }
                    $("#modal-add-member").modal("hide");
                })
            }
        }
    },
    mounted: function() {
        console.log("infoApp: ", this.infoApp);
    }
}