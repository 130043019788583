import resful from "@/services/resful.js";
export const memberSV = {
  getMemberSV: async function (data, next) {
    let res = await resful.get("/app/page", data);
    next(res);
  },
  addMemberSV: async function (data, next) {
    let res = await resful.post("/app/member_page", data);
    next(res);
  },
  removeMemberSV: async function (data, next) {
    let res = await resful.post("/app/member_page", data);
    next(res);
  },
  
};
