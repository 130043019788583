export default {
    data: function(){
        return {
            optionLangs: [
                {
                text: 'Vietnamese',
                value: 'vn'
                },
                {
                text: 'English',
                value: 'en'
                }
            ],
            langsl: {
                text: "",
                value: ""
            }
        };
    },
    methods: {
      callSetLangActions (event) {
        this.langsl = this.optionLangs.find((x) => x.value == event.target.getAttribute('value'));
        this.$store.dispatch('lang/setLang', event.target.getAttribute('value'));
        this.showmenu();
        localStorage.setItem("langao", JSON.stringify(this.langsl));
      },
      showmenu: function() {
          var wgMenu = $("#change-language .dropdown-menu");
          if (wgMenu.css("display") == "none") {
              wgMenu.show();
          } else {
              wgMenu.hide();
          }
      }
    },
    mounted: function() {
        if (localStorage.getItem("langao")) {
            this.langsl = JSON.parse(localStorage.getItem("langao"));
        } else {
            this.langsl = {
                text: 'Vietnamese',
                value: 'vn'
            };
        }
        this.$store.dispatch('lang/setLang', this.langsl.value);
    }
}