export default {
    props: ["shownt", "content"],
    data: function() {
        return {

        }
    },
    watch: {
        "shownt": function() {
            if (this.shownt) {
                $("#noti-app").show();
            }
        }
    },
    methods: {
        closeNoti: function() {
            $("#noti-app").hide();
        }
    },
    mounted: function() {
    }
}